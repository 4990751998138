import React, { useState, useEffect } from 'react';
import useStateRef from "react-usestateref";
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControl, Select, MenuItem, TextField } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import { motion } from "framer-motion";
import LinearProgress from '@mui/material/LinearProgress';


import { useStyles } from 'src/css/styles';

import BookingList from 'src/components/BookingList';

const TypeSelectView = (props) => {

    const { classes } = props;   
    const [, setSelectedAppointmentDate, selectedAppointmentDateRef] = useStateRef(null);
    const [selectedTime, setSelectedTime] = useState(0);
    const [selectedType, setSelectedType, selectedTypeRef] = useStateRef({ID:""});

    useEffect(() => {
      init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async()  => {
      if(props.lastAppointmentType) {
        setSelectedType(props.lastAppointmentType);
      }
    }

  const handleChangeSelectType = (e) => {
    props.appData.appointmentTypes.forEach((value) => {
      if (value.ID === e.target.value) {
        if(process.env.NODE_ENV === 'development') console.log("D: " + value.duration);
        setSelectedType(value);
        props.setLastAppointmentType(value);
      } 
    });
    clickDate("first");
    props.onAppointmentTypeChange(selectedTypeRef.current);
  } 

  /**
   * Handles the click event for a date on the booking portal calendar.
   * @async
   * @function
   * @param {string} type - The type of click event ("first", "select", or "dateSelected").
   * @param {Date|null} [value=null] - The value associated with the click event (optional).
   * @returns {Promise<void>}
   */
    const clickDate = async(type, value = null) => {
        if (type === "first") {
          setSelectedTime(0);
          setSelectedAppointmentDate(null);
        } else if (type === "select") {
          setSelectedTime(1);
        } else if (type === "dateSelected") {
          setSelectedAppointmentDate(moment(new Date(value)));
        }
      }

        /**
   * Returns the first available date from the given time list.
   * If a selected appointment date is provided, filters the time list to only include times on that date.
   * @param {Array} time_list - An array of available appointment times in the format "YYYY-MM-DD HH:mm".
   * @returns {Object} An object containing the display name of the first available date and the system date and time.
   * If no appointments are available, the display name will be "No Appointments Available".
   */
  const getFirstAvailableDate = (time_list) => {

    if(selectedAppointmentDateRef.current) {
      var tempList = time_list;
      time_list = [];
      tempList.forEach((time)=>{
        if(moment(time).isSame(selectedAppointmentDateRef.current, 'day')) {
          time_list.push(time);
        }
      });

      if(time_list.length < 1) return { display: 'No Appointments Available' };
    }

    var time_list_string = time_list[0];

    var check_date = moment(time_list_string, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD");
    var today_date = moment().format("YYYY-MM-DD");

    var date_name = moment(time_list_string, "YYYY-MM-DD HH:mm").format("dddd") + ", ";
    if (check_date === today_date) {
      date_name = "Today, ";
    } else {
      var tomorrow_date = moment().add(1, 'days').format("YYYY-MM-DD");
      if (check_date === tomorrow_date) {
        date_name = "Tomorrow, ";
      }
    }

    return {
      display: date_name + moment(time_list_string, "YYYY-MM-DD HH:mm").format("DD MMM h:mm A"), 
      systemDateTime: moment(time_list_string, "YYYY-MM-DD HH:mm"),
    };
  }

  /**
   * Handles the click event for a booking time slot.
   * @param {Object} time - The selected time slot object.
   * @param {string} id - The ID of the selected doctor.
   * @param {string} name - The name of the selected doctor.
   * @param {boolean} [hasPolicies=false] - Whether the selected branch has policies.
   */
    const bookingTimeClicked = (time, id, name) => {
      props.setBookingDetails({
        ...props.bookingDetails,
        'doctorID': id,
        'bookingTime': time.display,
        'systemDate': time.systemDateTime.format('YYYY-MM-DD'),
        'systemTime': time.systemDateTime.format('HH:mm'),
        'doctorName': name,
        'appointmentType': selectedTypeRef.current.ID,
        'appointmentTypeDesc': selectedTypeRef.current.description,
        'address': "123 St, Place, 3032"
      });
      props.handleWindowChange(true);
    }

    return (
      <div className='book-consultation-detail-content'> 
        <Grid container sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} >
            <div className='book-consultation-detail-detail' style={{paddingBottom: '20px'}}>
              <div className='title' style={{ marginLeft: '15px' }}>Please Select Appointment Type</div>
              <div className='selected-type' style={{ marginLeft: '15px' }}>
                <div className='selected-type-label'>I need</div>
                <div className='selected-type-select'>
                  {props.appData.appointmentTypes && props.appData.appointmentTypes.length === 0 ?
                    <Skeleton variant="rounded" width={300} height={40} style={{marginTop: '10px', marginLeft: '10px'}}/>
                    : <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                      <Select
                        className={classes.selectType}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        value={selectedType.ID}
                        onChange={handleChangeSelectType}>
                        <MenuItem className={classes.selectTypeItem} value={""} style={{ color: "#B5B5B5" }}>Appointment type</MenuItem>
                        {
                          props.appData.appointmentTypes && props.appData.appointmentTypes.map((appointmentType, index) => {
                            return (
                              <MenuItem key={index} className={classes.selectTypeItem} value={appointmentType.ID} sx={{ whiteSpace: "unset" }}>{appointmentType.description}</MenuItem>
                            );
                          })
                        }
                      </Select>
                    </FormControl>}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {selectedType.ID ?
              <motion.div animate={{ y: 0, opacity: 1 }} initial={{ y: 50, opacity: 0.5 }} transition={{ ease: "easeInOut", duration: 0.5 }} style={{marginLeft: '15px', marginBottom: '40px'}}>
                <div className='book-consultation-detail-detail'>
                  <div className='title'>When would you like your appointment?</div>
                  <div className='time-consultation-wrapper'>
                    <div className='time-consultation-btn-wrapper'>
                      <div className={`time-consultation-btn ${selectedTime === 0 ? "selected" : ""}`} onClick={() => { clickDate("first") }}>
                        {selectedTime === 0 ?
                          <RadioButtonCheckedOutlinedIcon className='circle-icon' fontSize='small' /> :
                          <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
                        <div className='text'>First Available</div>
                      </div>
                      <div className={`time-consultation-btn ${selectedTime === 1 ? "selected" : ""}`} style={{ marginLeft: "20px" }} onClick={() => clickDate("select")}>
                        {selectedTime === 1 ?
                          <RadioButtonCheckedOutlinedIcon className='circle-icon' fontSize='small' /> :
                          <CircleOutlinedIcon className='circle-icon' fontSize='small' />}
                        <div className='text'>On Specific Date</div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
              : ''}
          </Grid>
        </Grid>
        <div className='static-calendar-wrapper'>
          <motion.div animate={{ height: selectedTime === 1 ? 'auto' : 0 }} initial={{ height: 0 }} transition={{ ease: "easeInOut", duration: 0.5 }} style={{ overflow: 'hidden' }}>
            <div className='static-calendar-container'>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  orientation="landscape"
                  openTo="day"
                  className={classes.selectCalendar}
                  value={selectedAppointmentDateRef.current}
                  onChange={(newValue) => {
                    clickDate("dateSelected", newValue)
                  }}
                  showToolbar={false}
                  minDate={new Date()}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </motion.div>
        </div>
      {props.appData.practitioners && selectedType.ID ?
        <div style={{ background: "#f4f4f4", paddingBottom: '20px' }}>
          <BookingList 
            selectedAppointmentDate={selectedAppointmentDateRef.current} 
            bookingTimeClicked={bookingTimeClicked} 
            getFirstAvailableDate={getFirstAvailableDate} 
            practitioners={props.appData.practitioners} 
            setPractitioners={props.setPractitioners}
            isMobile={props.isMobile}
            selectedType={selectedTypeRef.current}/>
        </div>
        : selectedType.ID ? <LinearProgress/> : ''}
      </div>
    );
} 

export default withStyles(useStyles)(TypeSelectView);