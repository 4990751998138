import React, { useState, useEffect } from 'react';

const dataFields = [
    {name: 'location', options: ['WAT', 'BIM Test', 'Wantirna']},
    {name: 'test', options: ['true', 'false']},
]

const ErrorView = () => {
    return (
        <div className="select-view">
            <h3>This app has not been configured correctly, please contact your administrator for assistance</h3>
        </div>
    )
}

export default ErrorView;