import React, {useEffect, useState } from 'react';
import { Divider, Grid } from '@mui/material';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from "framer-motion";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

/**
 * Returns a list of available appointments for selected practitioners and appointment types.
 * @returns {JSX.Element} A JSX element containing the list of available appointments.
 */
const BookingList = (props) => {

    const [isMobile, setIsMobile] = useState(false);
    const [currentOpenDate, setCurrentOpenDate] = useState(null);
    const [currentOpenPractitioner, setCurrentOpenPractitioner] = useState(null);

    useEffect(() => {
      setIsMobile(document.getElementById('appContainer').clientWidth < 480);
    }, [props.practitioners]);
    

    /**
   * Returns a list of available booking times grouped by date.
   * @param {Array} new_value - An array of booking times in the format "YYYY-MM-DD HH:mm".
   * @param {string} id - The ID of the practitioner.
   * @param {string} name - The name of the practitioner.
   * @returns {JSX.Element} - A JSX element containing the list of available booking times grouped by date.
   */
    const getBookingListTimeDetail = (new_value, id, name, open) => {
        const list = [];

        var new_value_group = [];
        var new_value_current = "";
        var current_index = 0;

        new_value.map((value) => {
          var this_value = moment(value, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD")
          if (new_value_current !== this_value) {
              new_value_current = this_value;
              new_value_group.push([]);
              current_index++;
              new_value_group[current_index - 1].key = new_value_current;
              new_value_group[current_index - 1][new_value_current] = [];
          }

          new_value_group[current_index - 1][new_value_current].push(value);
          })

          new_value_group.map((value, index) => {
          var check_date = moment(value.key, "YYYY-MM-DD").format("YYYY-MM-DD");
          var today_date = moment().format("YYYY-MM-DD");

          var date_name = moment(value.key, "YYYY-MM-DD").format("ddd");
          var isTodayOrTomorrow = false;
          if (check_date === today_date) {
              date_name = "Today";
              isTodayOrTomorrow = true;
          } else {
              var tomorrow_date = moment().add(1, 'days').format("YYYY-MM-DD");
              if (check_date === tomorrow_date) {
              date_name = "Tomorrow";
              isTodayOrTomorrow = true;
              }
          }

          var date_name_string = "";
          if (isTodayOrTomorrow) {
              date_name_string = date_name;
          } else {
              date_name_string = date_name + " " + moment(value.key, "YYYY-MM-DD").format("DD MMM");
          }

          var date_list_string = [];
          var date_list = value[value.key];

          date_list.forEach((date_list_value, date_list_index) => {
            var check_date_list = moment(date_list_value, "YYYY-MM-DD hh:mm").format("hh:mm A");
            if(props.selectedAppointmentDate) {
              if(moment(date_list_value).isSame(props.selectedAppointmentDate, 'day')){
                date_list_string.push(
                    <div key={date_list_index} className='check_date_list_container-time' onClick={() => props.bookingTimeClicked(props.getFirstAvailableDate([date_list_value]), id, name)}>
                      {check_date_list}
                    </div> 
                )
              }
            } else {
                date_list_string.push(
                  <div key={date_list_index} className='check_date_list_container-time' onClick={() => props.bookingTimeClicked(props.getFirstAvailableDate([date_list_value]), id, name)}>
                    {check_date_list}
                  </div>)
            }      
          })

          if(date_list_string.length > 0) {
            if(index < 5 || props.selectedAppointmentDate) list.push(
              <Grid 
                item xs={12} 
                key={index} 
                onClick={() => setCurrentOpenDate(currentOpenDate === index ? null : index)} 
                style={currentOpenDate === index ? { background: 'white', color: 'black' } : { }}
              >
                <div className='date_list_container'>
                  <div className='date_list_container-header'>{date_name_string}</div>
                  {currentOpenDate === index ? <ArrowDropUpIcon fontSize='large' sx={{padding: '10px'}}/> : <ArrowDropDownIcon fontSize='large' sx={{padding: '10px'}}/> }
                </div>
                <motion.div 
                  animate={{ height: currentOpenDate === index ? 'auto' : 0, marginTop: currentOpenDate === index ? '-40px' : '0' }} 
                  initial={{ height: 0 }} transition={{ ease: "easeInOut", duration: 0.4 }} 
                  style={{overflow: 'hidden'}}
                >
                  <div className='check_date_list_container'>{date_list_string}</div>
                </motion.div>
              </Grid>
            )
          }
        })
        return (
          <motion.div 
            animate={{ height: open ? 'auto' : 0 }} 
            initial={{ height: 0 }} transition={{ ease: "easeInOut", duration: 0.5 }} 
            style={{ overflow: 'hidden' }}
          >
            <Grid container>{list}</Grid>
          </motion.div>
        )
    }

    /**
     * Toggles the 'open' property of a practitioner with the given ID and updates the state of practitioners.
     * @param {number} id - The ID of the practitioner to toggle.
     */
    const clickMoreTimes = (id) => {
      setCurrentOpenDate(null);
      setCurrentOpenPractitioner(id === currentOpenPractitioner ? null : id);
    }


    const list = [];
    props.practitioners.forEach((practitioner, index) => {
      if (
        practitioner.availabilities.length > 0 
        && (!props.selectedType.providers || props.selectedType.providers.includes(practitioner.ID))
        && props.getFirstAvailableDate(practitioner.availabilities).display !== "No Appointments Available"
      )  
      {  
        list.push(
          <div key={index} className={`appointment-list-list ${index === 0 ? "first" : ""}`}>
            <Grid container sx={{mb: '30px'}}>
              <Grid item md={2} sm={2} xs={4}>
                <div className='icon-wrapper'>
                  <img className='optometrist-image' alt="Practitioner" src={practitioner.imgSrc} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = "/static/images/optometrist/Account.png";
                  }}>
                  </img>
                </div>
              </Grid>
              <Grid item md={10} sm={10} xs={8}>
                <div className='appointment-list-list-top'>
                  <div className='appointment-time-wrapper' style={{ marginTop: props.isMobile ? "0px" : "40px" }}>
                    <Grid container>
                      <Grid item md={7} sm={6} xs={12}>
                        <div className='name'>{practitioner.firstName + ' ' + practitioner.lastName}</div>
                      </Grid>
                      <Grid item md={5} sm={6} xs={12}>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div className='time' style={{ marginLeft: '10px' }}
                            onClick={() => practitioner.availabilities.length > 0 ? props.bookingTimeClicked(props.getFirstAvailableDate(practitioner.availabilities), practitioner.ID, practitioner.firstName + ' ' + practitioner.lastName) : ""}>
                            {practitioner.availabilities.length > 0 ? props.getFirstAvailableDate(practitioner.availabilities).display : "No Appointments Available"}
                          </div>
                          {practitioner.availabilities.length > 0 ?
                            <>
                              {
                                !(practitioner.ID === currentOpenPractitioner) ?
                                  <div className='more_times_text' onClick={() => clickMoreTimes(practitioner.ID)}>More Times &#8250;</div>
                                  :
                                  <div className='more_times_text-icon' onClick={() => clickMoreTimes(practitioner.ID)}><CloseIcon sx={{fontSize: '40px'}}/></div>
                              }
                            </>
                            : ""}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
            {getBookingListTimeDetail(practitioner.availabilities, practitioner.ID, practitioner.firstName + ' ' + practitioner.lastName, practitioner.ID === currentOpenPractitioner)}
            {!practitioner.isLast ? <Divider /> : ''}
          </div>
        )
      }
    })
    
    return (<motion.div animate={{ height: '500px' }} initial={{ height: 0 }} transition={{ ease: "easeInOut", duration: 0.5 }} style={{overflow: 'hidden'}}><div className='appointment-list-wrapper'>{list}</div></motion.div>)
  }

export default BookingList;