/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import useStateRef from "react-usestateref";
import moment from 'moment';
import { Skeleton } from '@mui/material';

// Styles
import 'src/css/index.css';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from 'src/css/styles';

// Components
import StepsWrapper from 'src/components/StepsWrapper';
import TypeSelectView from 'src/Views/TypeSelectView';
import BookingView from 'src/Views/BookingView';
import ConfirmationView from 'src/Views/ConfirmationView';
import ThankyouView from './Views/ThankyouView';

// Models
import { Branch } from 'src/data/Branch';

const BookingPortal = () => {

  let params = new URLSearchParams(window.location.search);
  const [initialized, setInitialized] = useState(false);
  const [branch, setBranch, branchRef] = useStateRef(null);
  const [AppData, setAppData, AppDataRef] = useStateRef({});
  const [selectedStep, setSelectedStep] = useState(0);
  const [isMobile, setIsMobile, isMobileRef] = useStateRef(false);
  const [lastAppointmentType, setLastAppointmentType, lastAppointmentTypeRef] = useStateRef(null);
  const [bookingSubmitting, setBookingSubmitting] = useState(false);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [, setWindowNumber, windowNumberRef] = useStateRef(-1);
  const [, setBookingDetails, bookingDetailsRef] =
    useStateRef((params.get("test")) ?
      {
        'email': 'test@test.com',
        'DOB':  moment('2022-04-17'),
        'firstName': 'Test',
        'lastName': 'Patient',
        'phone': '0419830851',
        'gender': "Female",
        'visited': 'No',
      } : {
        'email': '',
        'DOB': '',
        'firstName': '',
        'lastName': '',
        'phone': '',
        'gender': 'Male',
        'visited': 'No',
      });

  // On app load
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!initialized) {
      setBranch(new Branch());
      init();
      setInitialized(true);
    }
  });

  const init = async () => {
    if(document.getElementById('booking-wrapper')) setIsMobile(params.get('noMobile') ? false : document.getElementById('booking-wrapper').clientWidth < 480);
    if(params.get('test')) console.log("Is Mobile: " + isMobileRef.current);

    const branch = new Branch();
    setBranch(branch);

    const connectionData = await branch.init();
    if(connectionData === undefined || connectionData === false) {
      setWindowNumber(-2);
      return;
    }

    const methods = branch.client.methods;
    setAppData({appointmentTypes: await methods.getAppointmentTypes(branch.identifier)});
    setWindowNumber(0);
    setAppData({...AppDataRef.current, practitioners: await methods.getPractioners(branch.identifier)});
  }

  const handleWindowChange = (isNext) => {

    window.top.postMessage("goTop", '*');
    
    if (isNext && windowNumberRef.current < 3) {
      setWindowNumber(windowNumberRef.current + 1);
    } else {
      setWindowNumber(windowNumberRef.current - 1);
    }

    if (windowNumberRef.current === 1) setSelectedStep(1);
    else if (windowNumberRef.current === 2) setSelectedStep(2);
    else if (windowNumberRef.current === 3) submitBooking();

  }

  const getWindow = () => {
    if (windowNumberRef.current === -2) {
      return <div className='error-page'>Error: Could not connect to the server.</div>;
    }

    if (windowNumberRef.current === -1) {
      return (
        <div style={{ padding: '20px 40px', marginTop: '20px' }}>
          <Skeleton variant="rounded" width={'300px'} height={30} />
          <div style={{ display: 'flex' }}>
            <Skeleton variant="rounded" width={'100px'} height={30} style={{ marginTop: "20px" }} />
            <Skeleton variant="rounded" width={'200px'} height={30} style={{ margin: "20px" }} />
          </div>
        </div>
      )
    }

    if (windowNumberRef.current === 0) {
      return (
        <TypeSelectView 
          branch={branchRef.current} 
          appData={AppDataRef.current}
          handleWindowChange={handleWindowChange} 
          bookingDetails={bookingDetailsRef.current} 
          setBookingDetails={setBookingDetails} 
          lastAppointmentType={lastAppointmentTypeRef.current}
          setLastAppointmentType={setLastAppointmentType}
          isMobile={isMobileRef.current}
          onAppointmentTypeChange={onAppointmentTypeChange}
          setPractitioners={setPractitioners}
        />
      )
    } else if (windowNumberRef.current === 1) {
      return (
        <BookingView 
          branch={branchRef.current} 
          handleWindowChange={handleWindowChange} 
          bookingDetails={bookingDetailsRef.current} 
          setBookingDetails={setBookingDetails} 
          isMobile={isMobileRef.current}
        />
      )
    } else if (windowNumberRef.current === 2) {
      return (
        <ConfirmationView 
          bookingDetails={bookingDetailsRef.current} 
          branch={branchRef.current} 
          handleWindowChange={handleWindowChange}
          isMobile={isMobileRef.current}
        />
      );
    } else if (windowNumberRef.current === 3) {
      return (
        <ThankyouView 
          loading={bookingSubmitting}
          branch={branchRef.current} 
          bookingDetails={bookingDetailsRef.current} 
          success={bookingSuccess} 
        />
      );
    }
  }

  const submitBooking = async() => {
    setBookingSubmitting(true);
    if (bookingDetailsRef.current) {
      sendPostRequest().then((success) => {
        setBookingSubmitting(false);
        setBookingSuccess(success);
      });
    }
  }

  /**
   * Sends a post request to create a new appointment and updates the UI accordingly.
   * @async
   * @function
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the post request was successful.
   */
  const sendPostRequest = async () => {
    const branch = branchRef.current;
    try {
      var success = await branch.client.methods.postAppointment({
        startdate: bookingDetailsRef.current.systemDate,
        starttime: bookingDetailsRef.current.systemTime,
        branchIdentifier: branch.identifier,
        practitionerID: bookingDetailsRef.current.doctorID,
        appointmentType: bookingDetailsRef.current.appointmentType,
        bookingDetails: bookingDetailsRef.current
      });
  
      let message = '?name=' + bookingDetailsRef.current.firstName
        + '&client_email=' + bookingDetailsRef.current.email
        + '&provider_email=' + branch.email
        + '&phone=' + branch.phone
        + '&address=' + encodeURIComponent(branch.address)
        + '&postcode=' + branch.postcode
        + '&state=' + branch.state
        + '&suburb=' + branch.suburb
        + '&provider=' + bookingDetailsRef.current.doctorName
        + '&practice_name=' + branch.name
        + '&time=' + moment(bookingDetailsRef.current.systemTime, "HH:mm").format("hh:mm A")
        + '&date=' + moment(bookingDetailsRef.current.systemDate).format("Do MMMM, YYYY")
        + '&brand=' + branch.clientName
        + '&type=' + bookingDetailsRef.current.appointmentTypeDesc;
  
      if (success) {
        if(branch.client.methods.sendConfirmMessage) branch.client.methods.sendConfirmMessage(message);
        if(branch.client.bookingCompleteEvents) branch.client.bookingCompleteEvents();
      }
      return success;
    } catch (error) {
      console.log(error);
    }
  }

  const onAppointmentTypeChange = async (type) => {
    // wait until appData has practitioners set then filter

    if (!AppDataRef.current.practitioners) {
      setTimeout(() => onAppointmentTypeChange(type), 100);
      return;
    }

    let tempList = [];

    AppDataRef.current.practitioners.forEach((practitioner) => {
      practitioner = branchRef.current.client.methods.checkPractitioner(practitioner, {ID: type.ID, duration: type.duration});
      if(practitioner.firstAvailable && (!type.providers || type.providers.includes(practitioner.ID))) 
      {
        if(tempList.length === 0) {
          tempList.push(practitioner)
        } else {
          let i = 0;
          while(i <= tempList.length && i < 1000) {

            let prev = tempList[i-1];
            let current = tempList[i];

            if(!prev) prev = "1970-01-01 00:00";
            else prev = prev.firstAvailable
            
            if(!current) current = moment().add(1, 'y').format("YYYY-MM-DD HH:mm");
            else current = current.firstAvailable;

            if(moment(practitioner.firstAvailable).isBetween(moment(prev), moment(current), undefined, '[]')) {
              tempList.splice(i, 0, practitioner);
              i = 1000;
            } 
            i++;
          }
        } 
      }
    });
    if(tempList.length > 0) tempList[tempList.length-1].isLast = true;
    setPractitioners(tempList);
  }

  const setPractitioners = (practitioners) => {
    setAppData({...AppDataRef.current, practitioners: practitioners});
  }

  return (
    <div className='book-consultation-detail-wrapper'>
      <div id="appContainer" className='book-consultation-detail-content-container-outer'>
        <div className='book-consultation-detail-content-container'>
          {params.get('site') ? <div className='book-consultation-detail-content-wrapper'>
            <style>
              {branch ? branch.customStyles : ''}
            </style>
            <StepsWrapper step={selectedStep} isMobile={isMobileRef.current} />
            {getWindow()}
          </div> : 'Please add URL parameters to view this page.'}
        </div>
      </div>
    </div>
  );

}

const connectAll = withStyles(useStyles, { withTheme: true })(BookingPortal);
export default connectAll;