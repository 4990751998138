/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import useStateRef from 'react-usestateref';
import { Grid } from '@mui/material';
import BookingDetailsSidebar from 'src/components/BookingDetailsSidebar';
import BookingForm from 'src/components/BookingForm';

const BookingView = (props) => {

    const [formCompleted, setFormCompleted] = useState(false);
    const [formData, setFormData, formDataRef] = useStateRef({});
    const [errors, setErrors] = useState({});
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        let data = {};
        props.branch.formFields.forEach(field => {
            if(props.bookingDetails[field.name]) {
                data[field.name] = props.bookingDetails[field.name];
            } else {
                data[field.name] = '';
            }
        });
        setFormData(data);
        setFormCompleted(validateFields());
    }, []);

    const validateFields = () => {
        var isValid = true;
        let currErrors = {};
        Object.keys(formDataRef.current).forEach( function(k) {
            if(formDataRef.current[k] === '') {
                currErrors[k] = "This field is required";
                isValid = false;
            } else if((k === 'email' && !formDataRef.current[k].match(/^[\w+-.]+@([\w-]+\.)+[\w-]{2,8}$/))) {
                currErrors[k] = "Please provide a valid Email";
                isValid = false;
            } else if((k === 'DOB') && !formDataRef.current[k]._i) {
                currErrors[k] = "Please provide a valid Date of Birth";
                isValid = false;
            } else if(k === 'phone' && !formDataRef.current[k].replace(/ /g, "").match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/)) {
                currErrors[k] = "Invalid Phone Number";
                isValid = false;
            } else {
                currErrors[k] = '';
            }
        });
        setErrors(currErrors);
        return isValid;
    }

    const formChangeHandler = (e) => {
        if(e) {
            if(!e.target) setFormData({...formDataRef.current, DOB: e});
            else if(!e.target.id && e.target.name) setFormData({...formDataRef.current, [e.target.name]: e.target.value});
            else setFormData({...formDataRef.current, [e.target.id]: e.target.value});
        }
        setFormCompleted(validateFields());
    }

    const submitForm = (isNext) => {
        let details = props.bookingDetails;
        for(let key in formDataRef.current) {
            details[key] = formDataRef.current[key];
        }
        props.setBookingDetails(details);
        setShowErrors(true);
        if(formCompleted || !isNext) props.handleWindowChange(isNext);
    }

    return (
        <div className='book-consultation-detail-content'>
            <Grid container>
                <Grid item sm={8} xs={12} sx={{ padding: 3, borderRight: props.isMobile ? "none" : "20px solid white" }}>
                    <div className='book-consultation-detail-detail d-flex-center' style={{paddingRight: '10px'}}>
                        <BookingForm 
                            formChangeHandler={formChangeHandler}
                            formData={formDataRef.current}
                            formFields={props.branch.formFields}
                            errors={errors}
                            showErrors={showErrors}
                            branch={props.branch}
                        />
                    </div>
                    <Grid container sx={{ mt: document.getElementById('appContainer').clientWidth < 480 ? '30px' : '100px', mb: '20px' }}>
                        <Grid item xs={5} md={5}>
                            <div 
                                className='back-button' 
                                onClick={() => submitForm(false)}
                            >
                                <p className='continue-button-text'>Back</p>
                            </div>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <div 
                                className={formCompleted ? 'continue-button' : 'continue-button-disabled'} 
                                onClick={() => submitForm(true)}
                            >
                                <p className='continue-button-text'>Continue</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={4} xs={12} sx={{ paddingLeft: 3 }}>
                    <BookingDetailsSidebar branchDetails={props.branch} details={props.bookingDetails} />
                </Grid>
            </Grid>
        </div>
    )
};

export default BookingView;